.dashboard-layout--logo {
  float: left;
  margin: 0 50px 0 0;
}

.dashboard-layout--logo-link {
  font-size: 18px;
  font-weight: bold;

}

.dashboard-layout--logo-link__dev {
  color: darkred;
}

.dashboard-layout--logo-link__dev:hover {
  color: red;
}

.dashboard-layout--header-menu {
  line-height: 64px;
}
