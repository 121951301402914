body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ql-editor {
  min-height: 60px;
}

/* antd form error + quill editor */
.ant-form-item-has-error .ql-toolbar {
  border-color: #ff4d4f;
}

.ant-form-item-has-error .ql-container {
  border-color: #ff4d4f;
}

.ant-input {
  color: black !important;
}

tbody {
  color: black !important;
}
