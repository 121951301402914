.notification-card {
  width: 100%;
  background-color: #fff;
  cursor: default;
}

.notification-card__is-not-delievered {
  background-color: #e6f7ff
}

.notification-card--title-timestamp {
  color: grey;
}

.notification-card--description {
  word-break: unset;
  white-space: pre-wrap;
}